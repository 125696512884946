import React from 'react';
import { useCommonStyles, customMakeStyles } from '@vegaplatformui/styling';
import { Stack } from '@mui/material';
import { DataGridPremium, GridToolbarQuickFilter } from '@mui/x-data-grid-premium';
import { GridSlotsComponent, GridToolbarContainer, GridPagination } from '@mui/x-data-grid';
import { StringCapitalizeAndSpace, StringCapitalizeAndSpaceAll } from '../utilities/string-formatter';
import { useTableUtilities } from '../use-table-utilities/use-table-utilities';
import { CustomDatagridColumnMenu } from '../custom-datagrid-column-menu/custom-datagrid-column-menu';
import { DataGridCustomToolbar } from '../utilities/datagrid-custom-toolbar';
import { tableIdentifierKeys } from '@vegaplatformui/sharedcomponents';

export interface IBulkProviderAccountsTableProps {
    selectedFiles: File[];
    setSelectedFiles: React.Dispatch<React.SetStateAction<File[]>>;
    fileRows: any[];
    headerKeys: string[];
}

const BulkProviderAccountsTable: React.FC<IBulkProviderAccountsTableProps> = (props) => {
    const localStyles = useStyles(props);
    const commonStyles = useCommonStyles();
    const cloudBulkProviderAccountsTableUtilities = useTableUtilities(tableIdentifierKeys.bulkProviderAccountsTable);

    React.useEffect(() => {
        cloudBulkProviderAccountsTableUtilities.registerTableControl();

        return () => {
            cloudBulkProviderAccountsTableUtilities.unregisterTableControl();
        };
    }, []);

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarQuickFilter className={commonStyles.classes.DataGridToolBarFilter} />
            </GridToolbarContainer>
        );
    }

    return (
        <Stack className={localStyles.cx(localStyles.classes.Stack)}>
            <DataGridPremium
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                        },
                    },
                    density: 'compact',
                }}
                pagination
                disableRowSelectionOnClick={true}
                autoHeight={true}
                columns={props.headerKeys.map((header: string) => {
                    return { field: header, headerName: StringCapitalizeAndSpaceAll(header.toLowerCase()), flex: 1 };
                })}
                rows={props.fileRows}
                slots={{
                    toolbar: DataGridCustomToolbar as GridSlotsComponent['toolbar'],
                    columnMenu: CustomDatagridColumnMenu,
                    pagination: GridPagination,
                }}
                slotProps={{
                    toolbar: {
                        tableIdentifier: tableIdentifierKeys.bulkProviderAccountsTable,
                    },
                }}
                density={cloudBulkProviderAccountsTableUtilities.currentTableControl?.density}
                onDensityChange={cloudBulkProviderAccountsTableUtilities.onDensityChange}
                columnVisibilityModel={cloudBulkProviderAccountsTableUtilities.currentTableControl?.columnModel}
                onColumnVisibilityModelChange={cloudBulkProviderAccountsTableUtilities.onColumnVisibilityModelChange}
                paginationModel={cloudBulkProviderAccountsTableUtilities.currentTableControl?.paginationModel}
                onPaginationModelChange={cloudBulkProviderAccountsTableUtilities.onPaginationModelChange}
            />
        </Stack>
    );
};

const useStyles = customMakeStyles<IBulkProviderAccountsTableProps>()((theme, props) => ({
    Stack: { width: '100%' },
}));

export { BulkProviderAccountsTable };
