import React, { useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Avatar, Menu, MenuItem, PaletteMode, Stack } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { sessionTexts } from '@vegaplatformui/utils';
import Keycloak from 'keycloak-js';
import { SetterOrUpdater } from 'recoil';
import { GeminiMenuItem } from '@vegaplatformui/sharedassets';
import mixpanel from 'mixpanel-browser';
import process from 'process';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUserProfileProps {
    keycloak: Keycloak;
    setAuthenticated: SetterOrUpdater<boolean>;
    logoutUrl: string;
    userName: string | undefined;
    themeState: PaletteMode;
    setThemeState: SetterOrUpdater<PaletteMode>;
    selectedMenuItem: GeminiMenuItem;
    isDrawerOpen: boolean;
    profilePhotoUrl: string;
}

const NavStyledTypo = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
}));

const ProfileMenuItem = styled(MenuItem)(({ theme }) => ({
    '& .MuiSvgIcon-root': {
        fill: theme.palette.action.active,
    },
}));
const UserProfile: React.FC<IUserProfileProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const [anchorProfile, setAnchorProfile] = useState<null | HTMLElement>(null);
    const profileMenu = Boolean(anchorProfile);

    const handleProfileClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorProfile(event.currentTarget);
    };
    const handleProfileClose = () => {
        setAnchorProfile(null);
    };

    const onLogoutClicked = () => {
        if (process.env?.NX_ENVIRONMENT === 'prod' && process.env.NX_MIXPANEL_PROJECT_TOKEN) {
            mixpanel.reset();
        }
        props.keycloak.logout();
        // localStorage.removeItem(sessionTexts.keycloak);
        localStorage.removeItem(sessionTexts.authenticated);
        props.setAuthenticated(false);
        localStorage.setItem(sessionTexts.route, props.logoutUrl);
    };

    return (
        <>
            <MenuItem className={cx(classes.ProfileMenuButton)} aria-busy={true} role={'menu'} tabIndex={0} onClick={handleProfileClick}>
                <Stack className={cx(classes.ProfileStack)} spacing={0} direction={'row'} alignItems={'center'}>
                    {props.profilePhotoUrl ? (
                        <Avatar className={cx(classes.ProfileIcon, classes.ProfileUrlIcon)} src={props.profilePhotoUrl} alt={`profile avatar`} />
                    ) : (
                        <AccountCircleIcon className={cx(classes.ProfileIcon)} />
                    )}
                    {props.isDrawerOpen && (
                        <Stack
                            className={cx(classes.MainMenuStack)}
                            justifyContent={'space-between'}
                            direction='row'
                            alignItems='center'
                            spacing={0.5}
                        >
                            <Typography className={cx(classes.NameText)} variant='body1'>
                                {props.userName}
                            </Typography>
                            <LogoutIcon fontSize={'small'} />
                        </Stack>
                    )}
                </Stack>
            </MenuItem>
            <Menu
                anchorEl={anchorProfile}
                id='account-menu'
                open={profileMenu}
                onClose={handleProfileClose}
                slotProps={{
                    paper: {
                        elevation: 0,
                        className: cx(classes.ProfileMenuPaper),
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <ProfileMenuItem onClick={() => onLogoutClicked()}>
                    <LogoutIcon />
                    <NavStyledTypo variant='body2' className={cx(classes.ProfileMenuPaperIcons)}>
                        Log Out
                    </NavStyledTypo>
                </ProfileMenuItem>
            </Menu>
        </>
    );
};

const useStyles = customMakeStyles<IUserProfileProps>()((theme, props) => ({
    ProfileMenuButton: {
        width: !props.isDrawerOpen ? '56px' : 'auto',
        borderRadius: '6px',
    },
    ProfileStack: { marginLeft: props.profilePhotoUrl ? '2px' : 0 },
    NameText: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: theme.palette.grey[700],
    },
    ProfileIcon: {
        minWidth: 0,
        marginRight: props.isDrawerOpen ? '1rem' : 0,
        justifyContent: 'center',
    },
    ProfileUrlIcon: {
        width: 20,
        height: 20,
    },
    ProfileMenuPaper: {
        overflow: 'visible',
        mt: 1.5,
        padding: '0.25rem',
        borderRadius: '6px',
        boxShadow: '0px 8px 9px -5px rgba(19, 19, 51, 0.2), 0px 15px 22px 2px rgba(19, 19, 51, 0.14), 0px 1px 18px 5px rgba(19, 19, 51, 0.12)',
        color: theme.palette.text.secondary,
        '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: theme.palette.background.paper,
            color: theme.palette.text.secondary,
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
        },
        '& .MuiList-root': {
            padding: 0,
        },
        '& .MuiMenuItem-root:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    ProfileMenuPaperIcons: { marginLeft: '.5rem' },
    ProfileMenuDivider: {
        margin: '0 !important',
        backgroundColor: theme.palette.mode === 'light' ? '#CECEDF' : '#3A3B84',
    },
    ProfileMenuItem: {
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0)',
        },
    },
    ProfileMenuTypography: {
        color: theme.palette.mode === 'light' ? theme.palette.text.primary : theme.palette.text.disabled,
    },
    MainMenuStack: {
        width: props.isDrawerOpen ? '11.75rem' : 'auto',
    },
}));

export { UserProfile };
