import { useMemo, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak-fork/web';
import { useQuery } from '@tanstack/react-query';
import { IDashboard } from '@vegaplatformui/models';
import { useSetRecoilState } from 'recoil';
import { SnackBarOptions } from '../recoil/atom';
import { SnackbarErrorOutput } from '../utilities/snackbar-error-output';
import { ReportsApi } from '@vegaplatformui/apis';
import { queryKeys } from './query-keys';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUseReportsApiHook {
    reports: IDashboard[];
    embeddedUrl: string;
    isDashboardLoading: boolean;
    isUrlLoading: boolean;
}

export interface IUseReportsApiProps {
    folderNames: string[];
}

export function useReportsApi(props: IUseReportsApiProps): IUseReportsApiHook {
    const { keycloak } = useKeycloak();
    const setSnackbarOptions = useSetRecoilState(SnackBarOptions);

    const reportsApi = useMemo(() => {
        const reportsApi = new ReportsApi();
        reportsApi.keycloak = keycloak;
        return reportsApi;
    }, [keycloak]);

    const {
        data: reports,
        isLoading: isDashboardLoading,
        isError: isDashboardError,
        isSuccess: isDashboardSuccess,
        error: dashboardError,
    } = useQuery({
        queryKey: [queryKeys.reportsApi.dashboards, props.folderNames],

        queryFn: async () => {
            const response = await reportsApi.listDashboards({ folderNames: props.folderNames });

            return response.data;
        },

        meta: {
            errorMessage: 'There was a problem getting dashboard Ids',
        },
    });

    const {
        data: embeddedUrl,
        isError: isEmbeddedUrlError,
        error: embeddedUrlError,
        isLoading: isLoadingisEmbeddedUrlLoading,
    } = useQuery({
        queryKey: [queryKeys.reportsApi.dashboardEmbeddedUrl, props.folderNames, reports],

        queryFn: async () => {
            const response = await reportsApi.postEmbeddedDashboardURL({
                dashboardId: reports?.map((dashboard: IDashboard) => dashboard.dashboardId)[0] ?? '',
            });
            return response.data;
        },

        // The query will not execute until the reports exists
        enabled: isDashboardSuccess && reports && reports.length > 0,

        gcTime: 0,

        meta: {
            errorMessage: 'There was a problem getting embedded Url',
        },
    });

    return {
        reports: reports ?? [],
        embeddedUrl: embeddedUrl ?? '',
        isDashboardLoading: isDashboardLoading,
        isUrlLoading: isLoadingisEmbeddedUrlLoading,
    };
}

export default useReportsApi;
