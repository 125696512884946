import { GeminiThemeType } from './geminiThemeType';
import createPalette from '@mui/material/styles/createPalette';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getContrastRatio } from '@mui/material';

interface UseLightPalette {
    theme: GeminiThemeType;
}

// ToDo seperate out these variants further

const paletteHelpers = createPalette({});

const useThemeVariant = (mode: string, primary_color?: string, secondary_color?: string): UseLightPalette => {
    const flags = useFlags();

    const lightPalette = createPalette({
        mode: 'light',
        // Contrast Threshold is currently 3 by default unless explicitly stated i.e. `contrastThreshold: 3,`
        common: {
            red: '#DA4167',
            tan: '#FAB616',
            lightRed: '#E47792',
            offBlack: '#131333',
            black: '#131333',
            white: '#FFFFFF',
            tableBorder: 'rgba(224, 224, 224, 1)',
        },
        // we don't need it if the focus state is based on the primary color, not in use
        customFocus: paletteHelpers.augmentColor({
            color: { main: '#7F56D9', contrastText: '#EDE9FE' },
            name: 'customFocus',
        }),
        primary: paletteHelpers.augmentColor({
            color: {
                main: flags.organizationSettingsTab && primary_color ? primary_color : '#7F56D9',
                contrastText:
                    getContrastRatio(flags.organizationSettingsTab && primary_color ? primary_color : '#7F56D9', paletteHelpers.common.white) >
                    paletteHelpers.contrastThreshold // grabs the palette's contrast threshold
                        ? paletteHelpers.common.white
                        : paletteHelpers.common.black,
            },
            name: 'primary',
        }),
        secondary: paletteHelpers.augmentColor({
            color: {
                main: flags.organizationSettingsTab && secondary_color ? secondary_color : '#1A1B60',
                contrastText:
                    getContrastRatio(flags.organizationSettingsTab && secondary_color ? secondary_color : '#1A1B60', paletteHelpers.common.white) >
                    paletteHelpers.contrastThreshold // grabs the palette's contrast threshold
                        ? paletteHelpers.common.white
                        : paletteHelpers.common.black,
            },
            name: 'secondary',
        }),
        error: paletteHelpers.augmentColor({
            color: { main: '#DA4167' },
            name: 'error',
        }),
        warning: paletteHelpers.augmentColor({
            color: { main: '#FAB616' },
            name: 'warning',
        }),
        info: paletteHelpers.augmentColor({
            color: { main: '#00AADF' },
            name: 'info',
        }),
        success: paletteHelpers.augmentColor({
            color: { main: '#57A773' },
            name: 'success',
        }),
        grey: {
            50: '#F1F3F9',
            100: '#DEDEE3',
            200: '#BCBCC7',
            300: '#8E8EA0',
            400: '#69697F',
            500: '#5A5A72',
            600: '#494A64',
            700: '#373854',
            800: '#232443',
            900: '#131333',
        },
        text: {
            primary: '#131333',
            secondary: '#55566C',
            disabled: 'rgba(26, 27, 96, 0.28)',
        },
        background: {
            paper: '#FFFFFF',
            default: '#F1F3F9',
        },
        action: {
            active: 'rgba(26, 27, 96, 0.54)',
            hover: 'rgba(19, 19, 51, 0.04)',
            hoverOpacity: 0.04,
            selected: 'rgba(90, 90, 114, 0.08)',
            selectedOpacity: 0.08,
            disabled: 'rgba(19, 19, 51, 0.26)',
            disabledBackground: 'rgba(19, 19, 51, 0.12)',
            disabledOpacity: 0.38,
            focus: 'rgba(19, 19, 51, 0.12)',
            focusOpacity: 0.12,
            activatedOpacity: 0.12,
        },
    });

    const lightTheme = {
        mode: mode,
        palette: lightPalette,
        shadows: [
            'none',
            '0px 3px 5px -1px rgba(19,19,51,0.2),0px 6px 10px 0px rgba(19,19,51,0.14),0px 1px 18px 0px rgba(19,19,51,0.12)',
            '0px 8px 9px -5px rgba(19,19,51,0.2),0px 15px 22px 2px rgba(19,19,51,0.14),0px 6px 28px 5px rgba(19,19,51,0.12)',
            '0px 11px 15px -7px rgba(19,19,51,0.2),0px 24px 38px 3px rgba(19,19,51,0.14),0px 9px 46px 8px rgba(19,19,51,0.12)',
            'none',
            'none',
            'none',
            'none',
            'none',
            'none',
            'none',
            'none',
            'none',
            'none',
            'none',
            'none',
            'none',
            'none',
            'none',
            'none',
            'none',
            'none',
            'none',
            'none',
            'none',
        ],
    } as GeminiThemeType;

    const darkPalette = createPalette({
        mode: 'dark',
        common: {
            black: '#131333',
            white: '#F8F8FF',
            tableBorder: 'rgba(224, 224, 224, 1)',
        },
        customFocus: paletteHelpers.augmentColor({
            color: { main: '#94949b' },
            name: 'customFocus',
        }),
        primary: paletteHelpers.augmentColor({
            color: { main: primary_color ? primary_color : '#5C31C2' },
            name: 'primary',
        }),
        secondary: paletteHelpers.augmentColor({
            color: { main: secondary_color ? secondary_color : '#1A1B60' },
            name: 'secondary',
        }),
        error: paletteHelpers.augmentColor({
            color: { main: '#DA4167' },
            name: 'error',
        }),
        warning: paletteHelpers.augmentColor({
            color: { main: '#FAB616' },
            name: 'warning',
        }),
        info: paletteHelpers.augmentColor({
            color: { main: '#00C2FF' },
            name: 'info',
        }),
        success: paletteHelpers.augmentColor({
            color: { main: '#57A773' },
            name: 'success',
        }),
        grey: {
            50: '#EEEEFA',
            100: '#DDDDF5',
            200: '#CECFEC',
            300: '#BABADE',
            400: '#A1A1CE',
            500: '#7C7DB8',
            600: '#6162A8',
            700: '#494A90',
            800: '#3B3C7D',
            900: '#131333',
        },
        text: {
            primary: '#F8F8FF',
            secondary: '#A7A4E1',
            disabled: '#7472A4',
        },
        background: {
            paper: '#3B3C7D',
            default: '#1A1B60',
        },
        action: {
            active: 'rgba(248, 248, 255, 0.56)',
            hover: 'rgba(41, 121, 241, 0.08)',
            hoverOpacity: 0.08,
            selected: 'rgba(41, 121, 241, 0.16)',
            selectedOpacity: 0.16,
            disabled: 'rgba(124, 125, 184, 0.3)',
            disabledBackground: 'rgba(124, 125, 184, 0.12)',
            disabledOpacity: 0.38,
            focus: 'rgba(124, 125, 184, 0.12)',
            focusOpacity: 0.12,
            activatedOpacity: 0.12,
        },
    });

    const darkTheme = {
        mode: mode,
        palette: darkPalette,
        shadows: [
            'none',
            '0px 3px 5px -1px rgba(19,19,51,0.2),0px 6px 10px 0px rgba(19,19,51,0.14),0px 1px 18px 0px rgba(19,19,51,0.12)',
            '0px 8px 9px -5px rgba(19,19,51,0.2),0px 15px 22px 2px rgba(19,19,51,0.14),0px 6px 28px 5px rgba(19,19,51,0.12)',
            '0px 11px 15px -7px rgba(19,19,51,0.2),0px 24px 38px 3px rgba(19,19,51,0.14),0px 9px 46px 8px rgba(19,19,51,0.12)',
            'none',
            'none',
            'none',
            'none',
            'none',
            'none',
            'none',
            'none',
            'none',
            'none',
            'none',
            'none',
            'none',
            'none',
            'none',
            'none',
            'none',
            'none',
            'none',
            'none',
            'none',
        ],
    } as GeminiThemeType;

    const theme = mode === 'dark' ? darkTheme : lightTheme;

    return { theme };
};

export { useThemeVariant, UseLightPalette };
