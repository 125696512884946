export const tableIdentifierKeys = {
    attachResourcesDialogTable: 'attach-resources-dialog-table',
    bulkProviderAccountsTable: 'bulk-provider-accounts-table',
    businessGroupingsTable: 'business-groupings-table',
    businessGroupDetailParkingScheduleTable: 'business-group-detail-parking-schedule-table',
    businessGroupingAssignmentRulesTable: 'business-grouping-assignment-rules-table',
    businessGroupingResourcesTable: 'business-grouping-resources-table',
    businessGroupingWizardUsersTable: 'business-grouping-wizard-users-table',
    cloudProviderAccountsTable: 'cloud-provider-accounts-table',
    createAdvancedParkingScheduleResourcesTable: 'create-advanced-parking-schedule-resources-table',
    createScheduleResourcesTable: 'create-schedule-resources-table',
    fileDownloadsTable: 'file-downloads-table',
    fileTransferHistoryTable: 'file-transfer-history-table',
    parkingScheduleTable_Old: 'parking-schedule-table-old',
    parkingScheduleTable: 'parking-schedule-table',
    resourceDetailParkingScheduleTable: 'resource-detail-parking-schedule-table',
    recommendationsTable: 'recommendations-table',
    recommendationDetailParkingScheduleTable: 'recommendation-detail-parking-schedule-table',
    resourceChildrenTable: 'resource-children-table',
    resourcePoolsDetailTable: 'resource-pools-detail-table',
    resourcesTable: 'resources-table',
    resourceRecommendationPanelTable: 'resource-recommendation-panel-table',
    rolesAndPermissionsTable: 'roles-and-permissions-table',
    spacesDetailTable: 'spaces-detail-table',
    spacesTable: 'spaces-table',
    ssoRoleMappingsTable: 'sso-role-mappings-table',
    userSettingsTable: 'user-settings-table',
    usersDeleteTableUtilities: 'users-delete-table-utilities',
    workloadsDetailTable: 'workloads-detail-table',
    chatbotDataTable: 'chatbot-data-table',
};
