import React from 'react';
import { Grid, Stack, Button, Avatar, Typography } from '@mui/material';
import { ILogoVariation } from '@vegaplatformui/models';

export interface IImageUploadProps {
    acceptedFormats?: string[];
    alt: string;
    direction?: 'row' | 'column';
    handleRemove: (variation: ILogoVariation) => void;
    handleUpload: (event: React.ChangeEvent<HTMLInputElement>, variation: ILogoVariation) => void;
    imageSrc: string;
    sizes: { maxWidth: number; maxHeight: number };
    spacing?: number | string;
    title: string;
    variation: ILogoVariation;
}

const ImageUpload: React.FC<IImageUploadProps> = (props) => {
    const {
        acceptedFormats = ['.jpeg', '.png', '.jpg', '.ico'],
        alt,
        direction = 'row',
        handleRemove,
        handleUpload,
        imageSrc,
        sizes,
        spacing = 2,
        title,
        variation,
    } = props;

    const onLogoUpload = (evt: React.ChangeEvent<HTMLInputElement>) => {
        handleUpload(evt, variation);
    };
    const onLogoRemoval = () => {
        handleRemove(variation);
    };

    return (
        <Stack direction={'column'} spacing={1}>
            <Typography variant='inherit' fontWeight={700} fontFamily={'Inter'} fontSize={'12px'}>
                {title}
            </Typography>
            <Stack direction={direction} spacing={spacing} alignItems={'center'}>
                <Grid item>
                    <Avatar src={imageSrc} alt={alt} sx={{ maxWidth: sizes.maxWidth, maxHeight: sizes.maxHeight }} />
                </Grid>
                <Grid item>
                    <Button disabled={!!imageSrc ? true : false} color='primary' variant='contained' component='label' size='small'>
                        Upload
                        {/* Using the imageSrc as key, so the input field gets re-rendered. Otherwise the logo removal
                        didn't reflect in the DOM state and it caused problem on file upload, not triggering onChange event */}
                        <input key={imageSrc} hidden id='logo' type='file' accept={acceptedFormats.join(',')} onChange={onLogoUpload} />
                    </Button>
                </Grid>
                {true && (
                    <Grid item>
                        <Button
                            disabled={!!imageSrc ? false : true}
                            color='primary'
                            variant='outlined'
                            component='label'
                            size='small'
                            onClick={onLogoRemoval}
                        >
                            Remove
                        </Button>
                    </Grid>
                )}
            </Stack>
        </Stack>
    );
};

export { ImageUpload };
