import React, { useEffect, useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { CreateScheduleResourcesTable } from '../../parking/create-schedule-resources-table';
import { IParkingPriorityGroup, IParkingSchedule, IResource } from '@vegaplatformui/models';

import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { ResourcesTabActionMenu } from './advanced-parking-resources-tab/resources-tab-action-menu';
import { AttachResourcesDialog } from '../attach-resources-dialog/attach-resources-dialog';
import { useRecoilState } from 'recoil';
import { areParkingScheduleTabsDirty } from '@vegaplatformui/sharedcomponents';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IResourcesTabProps {
    resources: IResource[];
    priorityGroups: IParkingPriorityGroup[];
    setPriorityGroups: React.Dispatch<React.SetStateAction<IParkingPriorityGroup[]>>;
    isLoading: boolean;
    isServerPaginated?: boolean;
    scheduleToEdit?: IParkingSchedule;
    resourcesToAdd: IResource[];
    setResourcesToAdd: React.Dispatch<React.SetStateAction<IResource[]>>;
    onAttachResources: (resources: IResource[]) => void;
    onDeleteResources: (resources: IResource[]) => void;
}

const ResourcesTab: React.FC<IResourcesTabProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const [selectedResources, setSelectedResources] = useState<IResource[]>([]);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isAttachResourcesDialogOpen, setIsAttachResourcesDialogOpen] = React.useState(false);
    const isActionsMenuOpen = Boolean(anchorEl);
    const [areTabsDirty, setAreTabsAreDirty] = useRecoilState(areParkingScheduleTabsDirty);

    useEffect(() => {
        onChangeResources(props.resourcesToAdd);
    }, [props.resourcesToAdd]);

    const onChangeResources = (resources: IResource[]) => {
        const newPriorityGroups = props.priorityGroups?.map((pg) => ({ ...pg, resources: resources })) ?? [];
        props.setPriorityGroups(newPriorityGroups);
    };

    const onClickAttachResources = () => {
        localStorage.setItem('previous_route', '/parking');
        setIsAttachResourcesDialogOpen(true);
    };

    return (
        <>
            <AttachResourcesDialog
                onAttachResources={props.onAttachResources}
                isLoading={props.isLoading}
                attachableResources={props.resources}
                isDialogOpen={isAttachResourcesDialogOpen}
                onCloseDialog={() => setIsAttachResourcesDialogOpen(false)}
            />
            <ResourcesTabActionMenu
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                selectedResources={selectedResources}
                onDeleteResources={props.onDeleteResources}
                resources={props.resources}
                priorityGroups={props.priorityGroups}
                setPriorityGroups={props.setPriorityGroups}
                onClearSelectedResources={() => setSelectedResources([])}
            />
            <Grid container>
                <Grid item xs={8}>
                    <Typography variant={'h6'}>{'Attached Resources'}</Typography>
                    <Typography variant={'subtitle1'}>Manage resources in this schedule</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2}>
                        <Button
                            variant={'cancel'}
                            size={'small'}
                            color={'inherit'}
                            onClick={(e) => setAnchorEl(e.currentTarget)}
                            endIcon={isActionsMenuOpen ? <ArrowDropUp /> : <ArrowDropDown />}
                            disabled={selectedResources.length < 2}
                        >
                            Actions
                        </Button>
                        <Button size={'small'} variant={'outlined'} onClick={onClickAttachResources}>
                            Attach Resources
                        </Button>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <CreateScheduleResourcesTable
                        updateResources={(resources) => {
                            !areTabsDirty && setAreTabsAreDirty(true);
                            return props.setResourcesToAdd(resources);
                        }}
                        key='selectResources'
                        resources={props.resourcesToAdd}
                        selectedResources={selectedResources ?? []}
                        setSelectedResources={setSelectedResources}
                        isLoading={props.isLoading}
                        isSelectable={true}
                    />
                </Grid>
            </Grid>
        </>
    );
};

const useStyles = customMakeStyles<IResourcesTabProps>()((theme, props) => ({}));

export { ResourcesTab };
