import React from 'react';
import { useResourcesApi } from '../../../../api-hooks/use-resources-api';
import { ResourcesTable } from '../../../../resources/resource-table/resources-table';
import { tableIdentifierKeys } from '@vegaplatformui/sharedcomponents';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBusinessGroupingWizardResourcesTabProps {}

const BusinessGroupingWizardResourcesTab: React.FC<IBusinessGroupingWizardResourcesTabProps> = (props) => {
    const businessGroupResourcesTableIdentifier = tableIdentifierKeys.businessGroupingResourcesTable;
    const { resources, isLoading, selectedResources, setSelectedResources } = useResourcesApi({
        tableIdentifier: businessGroupResourcesTableIdentifier,
    });
    const activeFields = [
        'resource_id',
        'name',
        'provider_str',
        'provider_account_canonical_name',
        'cloud_account_id',
        'type_str',
        'region',
        'state',
        'is_attached_to_schedule',
    ];

    return (
        <ResourcesTable
            resources={resources}
            setSelectedResources={setSelectedResources}
            selectedResources={selectedResources}
            isLoading={isLoading}
            isServerPaginated={true}
            resourcesTableIdentifier={businessGroupResourcesTableIdentifier}
            activeFields={activeFields}
            checkBoxSelection={false}
            shouldRegisterTableControl={true}
        />
    );
};

export { BusinessGroupingWizardResourcesTab };
