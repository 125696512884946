export enum VegaRoles {
    Analyst = 'analyst',
    DataReader = 'data_reader',
    Engineer = 'engineer',
    Executive = 'executive',
    GroupOwner = 'group_owner',
    ReadOnly = 'read_only',
    ReportAuthor = 'report_author',
    VegaAnalyst = 'vega_analyst',
    VegaSupport = 'vega_support',
}

export enum VegaLowerAdminRoles {
    Manager = 'manager',
    OrgGlobalAdmin = 'org_global_admin',
}

export enum VegaHigherAdminRoles {
    OrgOwner = 'org_owner',
    VegaAdmin = 'vega_admin',
}

export type IVegaRoleType = VegaRoles | VegaLowerAdminRoles | VegaHigherAdminRoles;

export enum VegaRoleHelperTypes {
    All = 'all',
    HigherLevel = 'higher_level',
    LowerLevel = 'lower_level',
}
