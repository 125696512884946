import React, { useEffect, useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { useRecoilState } from 'recoil';
import { defaultVegaTableControl, FileTransferCard, FileTransferHistoryCard, useFilesApi, vegaTableControls } from '@vegaplatformui/sharedcomponents';
import { Stack } from '@mui/material';
import { IFile } from '@vegaplatformui/models';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IFileTransferControllerProps {}

const FileTransferController: React.FC<IFileTransferControllerProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const [files, setFiles] = useState<File[]>([]);
    const [selectedFiles, setSelectedFiles] = useState<IFile[]>([]);
    const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false);
    const [fileToDelete, setFileToDelete] = useState<IFile>();
    const [tableControls, setTableControls] = useRecoilState(vegaTableControls);
    const filesApi = useFilesApi({ isFileUpload: true, setFilesToUpload: setFiles });

    useEffect(() => {
        setTableControls((controls) => {
            return [
                ...controls,
                {
                    key: 'file-transfer-history-table',
                    value: { ...defaultVegaTableControl },
                },
            ];
        });
        return () => {
            setTableControls((controls) => {
                return controls.filter((control) => control.key !== 'file-transfer-history-table');
            });
        };
    }, []);
    // useCommonPageHeader({ message: 'File Transfer Page header' });

    const onClickUploadFile = (data: File[]) => {
        filesApi.setFileToUpload(data[0]);
    };

    const onClickDeleteFile = (file: IFile) => {
        filesApi.deleteFiles([file]);
    };

    const onClickDeleteSelectedFiles = () => {
        filesApi.deleteFiles(selectedFiles);
    };

    return (
        <Stack spacing={1}>
            <FileTransferCard
                setSelectedFiles={setFiles}
                selectedFiles={files}
                onClickUploadFile={onClickUploadFile}
                isLoading={filesApi.isFileUploading}
            />
            <FileTransferHistoryCard
                historyFiles={filesApi.availableFiles}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                isLoading={filesApi.isLoading}
                onClickDeleteFile={onClickDeleteFile}
                onClickDeleteSelectedFiles={onClickDeleteSelectedFiles}
                confirmDeleteFile={onClickDeleteFile}
                setIsConfirmDeleteDialogOpen={setIsConfirmDeleteDialogOpen}
                isConfirmDeleteDialogOpen={isConfirmDeleteDialogOpen}
                setFileToDelete={setFileToDelete}
                fileToDelete={fileToDelete}
            />
        </Stack>
    );
};

const useStyles = customMakeStyles<IFileTransferControllerProps>()((theme, props) => ({}));

export { FileTransferController };
