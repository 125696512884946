import { ThemeProvider } from '@mui/material/styles';
import { customMakeStyles } from '@vegaplatformui/styling';
import { CssBaseline, PaletteMode } from '@mui/material';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { themeState } from '../recoil/atom';
import { OrganizationId, OrganizationLoading, queryKeys, SnackbarErrorOutput, UserId } from '@vegaplatformui/sharedcomponents';
import { LicenseInfo } from '@mui/x-license';
import * as process from 'process';
import { useKeycloak } from '@react-keycloak-fork/web';
import { SnackBarOptions, useOrganizationsApi } from '@vegaplatformui/sharedcomponents';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import useGeminiTheme from '../styling/use-gemini-theme';
import { ReportsApi, UserSettingApi } from '@vegaplatformui/apis';
import { LinearIndeterminateLoadingPageController } from '../components/loading-pages/linear-indeterminate-loading-page-controller';
import { App } from './app';
import { useQueryClient } from '@tanstack/react-query';
import { sessionTexts } from '@vegaplatformui/utils';
import mixpanel from 'mixpanel-browser';

dayjs.extend(utc);
dayjs.extend(timezone);

// eslint-disable-next-line @typescript-eslint/no-empty-function
const ColorModeContext = createContext({ toggleColorMode: () => {} });
export function UserDataLoadingHandler() {
    const [colorMode, setColorMode] = useRecoilState(themeState);
    LicenseInfo.setLicenseKey(process.env.NX_MUI_LICENSE || ' ');
    const setSnackbarOptions = useSetRecoilState(SnackBarOptions);
    const { keycloak } = useKeycloak();
    const ldClient = useLDClient();
    const domainSlug = window.location.hostname.split('.')[0];
    const [isLoading, setIsLoading] = useState(true);
    const [isPrefetching, setIsPrefetching] = useState(true);
    const organizationsApi = useOrganizationsApi({});
    const [organizationId, setOrganizationId] = useRecoilState(OrganizationId);
    const [userId, setUserId] = useRecoilState(UserId);
    const [isOrganizationIDLoading, setIsOrganizationIDLoading] = useRecoilState(OrganizationLoading);
    const userSettingApi = new UserSettingApi();
    const queryClient = useQueryClient();

    const reportsApi = useMemo(() => {
        const reportsApi = new ReportsApi();
        reportsApi.keycloak = keycloak;
        return reportsApi;
    }, [keycloak]);

    useEffect(() => {
        const isDev = process.env?.NX_ENVIRONMENT === 'dev';
        if (process.env?.NX_ENVIRONMENT === 'prod' && process.env.NX_MIXPANEL_PROJECT_TOKEN) {
            mixpanel.init(process.env.NX_MIXPANEL_PROJECT_TOKEN, {
                debug: isDev ?? false,
                track_pageview: 'url-with-path-and-query-string',
                persistence: 'localStorage',
            });
        }
    }, []);

    useEffect(() => {
        if (process.env.NX_ENVIRONMENT === 'prod') {
            if (userId !== undefined) {
                mixpanel.identify(userId);
            }
            if (keycloak?.tokenParsed && keycloak?.tokenParsed.email) {
                mixpanel.people.set_once({ Email: keycloak.tokenParsed.email });
                mixpanel.people.set_once({ $email: keycloak.tokenParsed.email });
                mixpanel.people.set_once({ $name: `${keycloak.tokenParsed.given_name} ${keycloak.tokenParsed.family_name}` });
                mixpanel.people.set_once({ Realm: domainSlug });
            }
        }
    }, [userId, keycloak.tokenParsed]);

    useEffect(() => {
        //Setup launch darkly for specific user this is where we can pass in sku and or roles
        if (keycloak?.tokenParsed?.keycloak_userid && keycloak?.tokenParsed?.email && keycloak?.tokenParsed?.realm_access?.roles) {
            const mappedRoleArray = (keycloak.tokenParsed.mapped_roles && JSON.parse(keycloak.tokenParsed.mapped_roles)) ?? [];
            ldClient
                ?.identify({
                    name: keycloak.tokenParsed.email,
                    key: keycloak.tokenParsed.keycloak_userid,
                    custom: {
                        domainSlug: domainSlug,
                        roles: [...new Set([...keycloak.tokenParsed.realm_access.roles, ...mappedRoleArray])],
                        email: keycloak.tokenParsed.email,
                    },
                })
                .then(() => setIsLoading(false));
        }
        if (keycloak?.tokenParsed?.preferred_username) {
            userSettingApi.keycloak = keycloak;
            if (organizationId === '') {
                setIsOrganizationIDLoading(true);
            }
            userSettingApi
                .getUser({ username: keycloak.tokenParsed?.preferred_username })
                .then((response) => {
                    setOrganizationId(response.data.organization_id ?? '');
                    setUserId(response.data.id ?? undefined);
                    setIsOrganizationIDLoading(false);
                })
                .catch((error) => {
                    setSnackbarOptions({
                        snackBarProps: { open: true, autoHideDuration: 6000 },
                        alertProps: { severity: 'error' },
                        message: `Failed to get User Info, Organization ID: ${SnackbarErrorOutput(error)}`,
                    });
                    setIsOrganizationIDLoading(false);
                });
        }
    }, [keycloak.tokenParsed, keycloak]);

    useEffect(() => {
        if (keycloak.authenticated) {
            sessionStorage.setItem(sessionTexts.keycloakUserId, keycloak.tokenParsed?.keycloak_userid);
            setIsPrefetching(true);
            prefetchData().then(() => {
                setIsPrefetching(false);
            });
        }
    }, [keycloak.authenticated]);

    const prefetchData = async () => {
        //Prefetch all the dashboards and store in React Query
        await queryClient.prefetchQuery({
            queryKey: [queryKeys.reportsApi.dashboards, ['executivekpis']],
            queryFn: () => getDashboards(['executivekpis']),
        });
        await queryClient.prefetchQuery({
            queryKey: [queryKeys.reportsApi.dashboards, ['navigator']],
            queryFn: () => getDashboards(['navigator']),
        });
        await queryClient.prefetchQuery({
            queryKey: [queryKeys.reportsApi.dashboards, ['anomalies']],
            queryFn: () => getDashboards(['anomalies']),
        });
        await queryClient.prefetchQuery({
            queryKey: [queryKeys.reportsApi.dashboards, ['forecasting']],
            queryFn: () => getDashboards(['forecasting']),
        });
        await queryClient.prefetchQuery({
            queryKey: [queryKeys.reportsApi.dashboards, ['recommendations']],
            queryFn: () => getDashboards(['recommendations']),
        });
        await queryClient.prefetchQuery({
            queryKey: [queryKeys.reportsApi.dashboards, ['committeduse_aws']],
            queryFn: () => getDashboards(['committeduse_aws']),
        });
        await queryClient.prefetchQuery({
            queryKey: [queryKeys.reportsApi.dashboards, ['committeduse_azure']],
            queryFn: () => getDashboards(['committeduse_azure']),
        });
        await queryClient.prefetchQuery({
            queryKey: [queryKeys.reportsApi.dashboards, ['committeduse_gcp']],
            queryFn: () => getDashboards(['committeduse_gcp']),
        });
    };

    const getDashboards = async (folderNames: string[]) => {
        const response = await reportsApi.listDashboards({ folderNames: folderNames });

        return response.data;
    };

    const colorModeToggle = useMemo(
        () => ({
            // The dark mode switch would invoke this method
            toggleColorMode: () => {
                setColorMode((prevMode: PaletteMode) => (prevMode === 'light' ? 'dark' : 'light'));
            },
        }),
        [setColorMode]
    );

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ColorModeContext.Provider value={colorModeToggle}>
                <ThemeProvider
                    theme={useGeminiTheme(colorMode, organizationsApi.themeData?.primary_color, organizationsApi.themeData?.secondary_color).theme}
                >
                    <CssBaseline />
                    {isOrganizationIDLoading ||
                    organizationsApi.isThemeDataLoading ||
                    organizationsApi.isOrganizationLogoDataLoading ||
                    !keycloak.authenticated ? (
                        <LinearIndeterminateLoadingPageController />
                    ) : (
                        <App />
                    )}
                </ThemeProvider>
            </ColorModeContext.Provider>
        </LocalizationProvider>
    );
}

const useStyles = customMakeStyles()((theme) => ({
    listItem: {
        display: 'list-item',
        padding: 0,
    },
    variantError: {
        backgroundColor: theme.palette.error.main,
    },
    variantInfo: {
        backgroundColor: '#E5FFF3',
        color: '#343434',
    },
}));

export default UserDataLoadingHandler;
