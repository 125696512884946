import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar/AppBar';
import { Autocomplete, IconButton, ListItem, ListItemButton, ListItemText, PaletteMode, Stack, TextField, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import { SetterOrUpdater } from 'recoil';
import { Article, ChevronLeft, ChevronRight, MenuBook } from '@mui/icons-material';
import { customMakeStyles } from '@vegaplatformui/styling';
import { GeminiMenuItem } from '@vegaplatformui/sharedassets';
import { StyledToolTip } from '../utilities/styled-tooltip';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { closedDrawerWidth, openDrawerWidth } from '../constants/constants';
import { AutoComplete } from 'antd';
import { ITimeZone, timezoneSet } from '@vegaplatformui/utils';
import { useOrganizationsChildrenApi } from '../api-hooks/use-organization-children';
import { IOrganizationMetadata } from '@vegaplatformui/models';
import { useNavigate } from 'react-router-dom';
import { AppbarUrlNavigator } from './appbar-url-navigator';

export interface ICustomAppBarProps {
    selectedMenuItem: GeminiMenuItem;
    menuItems: GeminiMenuItem[];
    themeState: PaletteMode;
    isDrawerOpen: boolean;
    setIsDrawerOpen: SetterOrUpdater<boolean>;
}

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    background: theme.palette.background.default,
    boxShadow: 'none',

    ...(open
        ? {
              marginLeft: openDrawerWidth,
              width: `calc(100% - ${openDrawerWidth}px)`,
              transition: theme.transitions.create(['all'], {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
              }),
          }
        : {
              marginLeft: closedDrawerWidth,
              width: `calc(100% - ${closedDrawerWidth}px)`,
              transition: theme.transitions.create(['all'], {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen,
              }),
          }),
}));

const NavToolBar = styled(Toolbar)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
}));

const CircularIconButton = styled(IconButton)(({ theme }) => ({
    height: '1.75rem',
    width: '1.75rem',
    backgroundColor: theme.palette.common.white,
    borderRadius: '16px',
    boxShadow: theme.shadows[2],
    '&:hover': {
        backgroundColor: theme.palette.grey.A200,
    },
}));

const CustomAppBar: React.FC<ICustomAppBarProps> = (props) => {
    const theme = useTheme();
    const { cx, classes } = useStyles(props);
    const flags = useFlags();

    const handleDrawer = () => {
        props.setIsDrawerOpen(!props.isDrawerOpen);
    };
    return (
        <AppBar position='fixed' open={props.isDrawerOpen} theme={theme}>
            <NavToolBar className={cx(classes.NavToolBar)}>
                <Stack spacing={1} direction={'row'} className={cx(classes.SidebarControl)}>
                    <CircularIconButton onClick={handleDrawer} title={'toggle side bar'}>
                        {props.isDrawerOpen ? (
                            <StyledToolTip key={'minimize'} arrow={true} title={'Minimize sidebar'}>
                                <ChevronLeft className={cx(classes.IconFillBlack)} />
                            </StyledToolTip>
                        ) : (
                            <StyledToolTip key={'expand'} arrow={true} title={'Expand sidebar'}>
                                <ChevronRight className={cx(classes.IconFillBlack)} />
                            </StyledToolTip>
                        )}
                    </CircularIconButton>
                    <Stack alignItems={'center'} spacing={1} direction={'row'}>
                        {props.selectedMenuItem && props.selectedMenuItem.icon && (
                            <props.selectedMenuItem.icon data-testid={'page-icon'} className={cx(classes.IconFillBlack)} />
                        )}
                        <Typography data-testid={'page-title'} variant={'h6'} className={cx(classes.LocationText)}>
                            {props.selectedMenuItem && props.selectedMenuItem.title}
                        </Typography>
                    </Stack>
                </Stack>
                <Stack width={'50%'} direction={'row'} spacing={1} alignItems='center' justifyContent={'flex-end'}>
                    {flags.portalNavigationDropdown && <AppbarUrlNavigator />}
                    {/*TODO We can refactor this CustomNotificationBell for integrating with Knock when we get to that*/}
                    {/*{flags.notificationService && (*/}
                    {/*    <CustomNotificationBell*/}
                    {/*        tooltipText={'Notifications center'}*/}
                    {/*        unseenCount={unseenCount}*/}
                    {/*        unseenBadgeColor={unseenBadgeColor}*/}
                    {/*        unseenBadgeBackgroundColor={unseenBadgeBackgroundColor}*/}
                    {/*    />*/}
                    {/*)}*/}
                    <StyledToolTip arrow={true} title={'Release notes'}>
                        <IconButton href={'https://docs.vegacloud.io/docs/releasenotes'} target='_blank' rel='noreferrer noopener'>
                            <Article />
                        </IconButton>
                    </StyledToolTip>
                    <StyledToolTip arrow={true} title={'Vega documentation'}>
                        <IconButton href={props.selectedMenuItem?.documentationLink ?? ''} target='_blank' rel='noreferrer noopener'>
                            <MenuBook />
                        </IconButton>
                    </StyledToolTip>
                </Stack>
            </NavToolBar>
        </AppBar>
    );
};

const useStyles = customMakeStyles<ICustomAppBarProps>()((theme, props) => ({
    SidebarControl: { marginLeft: '-2.5rem' },
    IconFillBlack: {
        fill: props.themeState === 'light' ? theme.palette.text.primary : theme.palette.text.secondary,
    },
    LocationText: { color: props.themeState === 'light' ? theme.palette.text.primary : theme.palette.text.secondary },
    TitleText: {
        marginTop: '-4.5rem',
    },
    NavToolBar: {
        borderBottom: `1px solid ${theme.palette.grey[100]}`,
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.background.paper : 'rgba(0,0,0,0)',
    },
}));

export { CustomAppBar };
