import React from 'react';
import { Box } from '@mui/material';
import { DataGridPremium, GridColDef, useGridApiRef } from '@mui/x-data-grid-premium';
import { GridRowSelectionModel, GridSlotsComponent } from '@mui/x-data-grid';
import { IResource, IVegaContainer } from '@vegaplatformui/models';
import { useTableUtilities } from '../use-table-utilities/use-table-utilities';
import { DataGridCustomToolbar } from '../utilities/datagrid-custom-toolbar';
import { CustomDatagridColumnMenu } from '../custom-datagrid-column-menu/custom-datagrid-column-menu';
import { tableIdentifierKeys } from '@vegaplatformui/sharedcomponents';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IContainerFormTableProps {
    columns: GridColDef[];
    rows: IResource[];
    setSelectedChildren: React.Dispatch<React.SetStateAction<IResource[]>>;
    selectedChildren: IResource[] | undefined;
    isLoading: boolean;
    containerToEdit?: IVegaContainer;
}

const ContainerFormTable: React.FC<IContainerFormTableProps> = (props) => {
    const apiRef = useGridApiRef();
    const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>(
        props.rows.filter((children) => props.containerToEdit?.id === children.resource_pool_id).map((children) => children.id) ?? []
    );
    const resourceChildrenTableUtilities = useTableUtilities(tableIdentifierKeys.resourceChildrenTable);

    React.useEffect(() => {
        resourceChildrenTableUtilities.registerTableControl();
        return () => {
            resourceChildrenTableUtilities.unregisterTableControl();
        };
    }, []);

    const onRowsSelectionHandler = (gridSelectionModel: GridRowSelectionModel) => {
        setSelectionModel(gridSelectionModel);
        props.setSelectedChildren([
            ...new Set([
                ...gridSelectionModel.map(
                    (id) => props.rows.find((child) => child.id === id) ?? (props.selectedChildren?.find((child) => child.id === id) as IResource)
                ),
            ]),
        ]);
    };

    const getTogglableColumns = (columns: GridColDef[]) => {
        return columns.filter((column) => column.field !== 'actions' && column.field !== '__check__').map((column) => column.field);
    };

    return (
        <Box>
            <DataGridPremium
                filterModel={resourceChildrenTableUtilities.currentTableControl?.filterModel}
                onFilterModelChange={resourceChildrenTableUtilities.onFilterModelChange}
                rowCount={(resourceChildrenTableUtilities.currentTableControl && resourceChildrenTableUtilities.currentTableControl.totalRows) ?? 0}
                initialState={{}}
                filterMode={'server'}
                sortingMode={'server'}
                paginationMode={'server'}
                apiRef={apiRef}
                pagination={true}
                autoHeight={true}
                columns={[...props.columns]}
                keepNonExistentRowsSelected={true}
                rows={props.rows}
                onRowSelectionModelChange={(gridSelectionModel: GridRowSelectionModel) => onRowsSelectionHandler(gridSelectionModel)}
                rowSelectionModel={selectionModel}
                checkboxSelection={true}
                disableRowSelectionOnClick={true}
                slots={{
                    toolbar: DataGridCustomToolbar as GridSlotsComponent['toolbar'],
                    columnMenu: CustomDatagridColumnMenu,
                }}
                slotProps={{
                    toolbar: {
                        tableIdentifier: tableIdentifierKeys.resourceChildrenTable,
                    },
                    pagination: {
                        page: resourceChildrenTableUtilities.currentTableControl?.paginationModel?.page,
                        onPageChange: resourceChildrenTableUtilities.onPaginationModelPageChange,
                        count: resourceChildrenTableUtilities.currentTableControl?.totalRows,
                        rowsPerPage: resourceChildrenTableUtilities.currentTableControl?.paginationModel?.pageSize,
                    },
                    columnsManagement: {
                        getTogglableColumns,
                    },
                }}
                density={resourceChildrenTableUtilities.currentTableControl?.density}
                onDensityChange={resourceChildrenTableUtilities.onDensityChange}
                paginationModel={resourceChildrenTableUtilities.currentTableControl?.paginationModel}
                sortModel={resourceChildrenTableUtilities.currentTableControl?.sortModel}
                onPaginationModelChange={resourceChildrenTableUtilities.onPaginationModelChange}
                onSortModelChange={resourceChildrenTableUtilities.onSortModelChange}
                columnVisibilityModel={resourceChildrenTableUtilities.currentTableControl?.columnModel}
                onColumnVisibilityModelChange={resourceChildrenTableUtilities.onColumnVisibilityModelChange}
                loading={props.isLoading}
            />
        </Box>
    );
};

export { ContainerFormTable };
