import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { GridPagination, GridRowSelectionModel, GridSlotsComponent } from '@mui/x-data-grid';
import { DataGridPremium, GridColDef, GridRenderCellParams, GridRowParams, GridValueOptionsParams, ValueOptions } from '@mui/x-data-grid-premium';
import { CloudProvidersTypes, ICloudProviderAccount, IResource } from '@vegaplatformui/models';
import { CloudProviderIcon } from '../utilities/logo-selector';
import { useTableUtilities } from '../use-table-utilities/use-table-utilities';
import { DataGridCellTooltip } from '../utilities/datagrid-cell-tooltip';
import { DataGridCustomToolbar } from '../utilities/datagrid-custom-toolbar';
import { Button, Grid, Typography } from '@mui/material';
import { CustomGridColSingleSelectOperatorDef, CustomGridColStringOperatorDef } from '../utilities/custom-grid-col-string-operator-def';
import { CustomDatagridColumnMenu } from '../custom-datagrid-column-menu/custom-datagrid-column-menu';
import { tableIdentifierKeys } from '../use-table-utilities/table-identifier-keys';
import { SoftDeletedResourceNotice } from '../drawer-scheduler/drawer-scheduler-tabs/soft-deleted-resource-notice';
import { getProviderFilterOptions } from '@vegaplatformui/utils';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICreateScheduleAttachedResourcesTableProps {
    resources: IResource[];
    selectedResources: IResource[];
    setSelectedResources: (resources: IResource[]) => void;
    isLoading: boolean;
    isSelectable: boolean;
    updateResources: (resources: IResource[]) => void;
}

const CreateScheduleResourcesTable: React.FC<ICreateScheduleAttachedResourcesTableProps> = (props) => {
    const createScheduleResourceTableControls = useTableUtilities(tableIdentifierKeys.createScheduleResourcesTable);
    const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>(props.selectedResources.map((resource) => resource?.id) ?? []);

    React.useEffect(() => {
        createScheduleResourceTableControls.registerTableControl();

        return () => {
            createScheduleResourceTableControls.unregisterTableControl();
        };
    }, []);

    const columns: GridColDef[] = [
        {
            ...CustomGridColStringOperatorDef,
            field: 'alert',
            headerName: '',
            flex: 0.25,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            valueGetter: (value: boolean, row: IResource, column, apiRef) => (!!row.deleted_at ? row.deleted_at : undefined),
            renderCell: (params: GridRenderCellParams<IResource>) => {
                switch (params.value) {
                    case undefined:
                    case null:
                        return <></>;
                    default:
                        return <SoftDeletedResourceNotice />;
                }
            },
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'name',
            headerName: 'Name',
            flex: 2,
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'resource_id',
            headerName: 'Resource ID',
            flex: 1.5,
        },
        {
            ...CustomGridColSingleSelectOperatorDef,
            field: 'provider_str',
            headerName: 'Provider',
            flex: 1,
            renderCell: (params: GridRenderCellParams<IResource>) => (params.value ? <CloudProviderIcon cloudProvider={params.value} /> : ''),
            valueFormatter: (value: string, row: IResource) => (value ? value : ''),
            type: 'singleSelect',
            valueOptions: (params: GridValueOptionsParams<IResource>): ValueOptions[] => {
                return getProviderFilterOptions();
            },
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'provider_account_canonical_name',
            headerName: 'Account',
            flex: 1,
            valueGetter: (value: string, row: IResource) => {
                return row.provider_account?.canonical_name;
            },
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'cloud_account_id',
            headerName: 'Account ID',
            flex: 1,
            valueGetter: (value: string, row: IResource) => {
                return row.provider_account?.account_id;
            },
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'type_str',
            headerName: 'Instance Type',
            flex: 1,
        },
        /*  {
            field: 'cost',
            headerName: 'Cost/Mo',
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => FormatNumberUSDHundredth(params.value),
        },*/
        {
            ...CustomGridColStringOperatorDef,
            field: 'region',
            headerName: 'Region',
            flex: 1,
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'Actions',
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams<IResource>) => {
                return (
                    <Button
                        onClick={() => {
                            props.updateResources(props.resources.filter((item) => item.id !== params.row.id));
                        }}
                        disabled={props.selectedResources.length > 1}
                        variant={'text'}
                    >
                        Remove
                    </Button>
                );
            },
        },
    ];

    columns.map((column) => {
        if (!column.renderCell) column.renderCell = DataGridCellTooltip;
        return column;
    });

    const onRowsSelectionHandler = (gridSelectionModel: GridRowSelectionModel) => {
        setSelectionModel(gridSelectionModel);

        //TODO this was the old way that the selection model was being selected. This was causing duplicates though. So I just incorporated how all of the other selection models are doing it
        // const selected = [...props.resources, ...props.selectedResources].flat().filter((resource) => gridSelectionModel.includes(resource?.id));
        // let uniqueItems = [...new Set(selected)];
        // console.log(uniqueItems);
        // props.setSelectedResources(uniqueItems);
        props.setSelectedResources(
            gridSelectionModel.map(
                (id) => [...new Set([...props.resources, ...props.selectedResources].flat())].find((resource) => resource.id === id) as IResource
            )
        );
    };

    const getTogglableColumns = (columns: GridColDef[]) => {
        return columns
            .filter((column) => column.field !== 'Actions' && column.field !== '__check__' && column.field !== 'alert')
            .map((column) => column.field);
    };

    return (
        <DataGridPremium
            autoHeight={true}
            pagination={true}
            isRowSelectable={(params: GridRowParams<IResource>) => {
                return !params.row.deleted_at;
            }}
            keepNonExistentRowsSelected={true}
            columns={columns}
            rows={props.resources}
            onRowSelectionModelChange={(gridSelectionModel: GridRowSelectionModel) => onRowsSelectionHandler(gridSelectionModel)}
            rowSelectionModel={selectionModel}
            checkboxSelection={props.isSelectable}
            disableRowSelectionOnClick={true}
            slots={{
                toolbar: DataGridCustomToolbar as GridSlotsComponent['toolbar'],
                columnMenu: CustomDatagridColumnMenu,
                pagination: GridPagination,
            }}
            slotProps={{
                toolbar: {
                    tableIdentifier: tableIdentifierKeys.createScheduleResourcesTable,
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
                columnsManagement: {
                    getTogglableColumns,
                },
                noRowsOverlay: {
                    action: 'attaching a resource',
                },
            }}
            density={createScheduleResourceTableControls.currentTableControl?.density}
            onDensityChange={createScheduleResourceTableControls.onDensityChange}
            paginationModel={createScheduleResourceTableControls.currentTableControl?.paginationModel}
            checkboxSelectionVisibleOnly
            filterMode={'client'}
            sortingMode={'client'}
            onSortModelChange={createScheduleResourceTableControls.onSortModelChange}
            onPaginationModelChange={createScheduleResourceTableControls.onPaginationModelChange}
            columnVisibilityModel={createScheduleResourceTableControls.currentTableControl?.columnModel}
            onColumnVisibilityModelChange={createScheduleResourceTableControls.onColumnVisibilityModelChange}
            loading={props.isLoading}
            onFilterModelChange={createScheduleResourceTableControls.onFilterModelChange}
            hideFooter={!!props.resources.length ? false : true}
        />
    );
};

export { CreateScheduleResourcesTable };
