import React, { useEffect } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Avatar, Fab, Popper } from '@mui/material';
import { chainEventHandlers } from 'material-ui-popup-state/chainEventHandlers';
import { bindPopper, bindToggle, InjectedProps } from 'material-ui-popup-state';
import { CloseRounded, SmartToy } from '@mui/icons-material';
import { CustomChatbotPopupContentControl } from './custom-chatbot-popup-content-control';
import { useChatbotApi } from '../api-hooks/use-chatbot-api';
import { ChatbotDataDialog } from './chat-navigation-content/chatbot-data-dialog';
import { tableIdentifierKeys, useTableUtilities } from '@vegaplatformui/sharedcomponents';
import { Cosmoo, CosmooNew, CosmooV2 } from '@vegaplatformui/sharedassets';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICustomChatbotContentProps {
    popupState: InjectedProps;
}

const CustomChatbotContent: React.FC<ICustomChatbotContentProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { popupState } = props;
    const chatbotApi = useChatbotApi({ isOpen: popupState.isOpen });
    const chatbotDataTableUtilities = useTableUtilities(tableIdentifierKeys.chatbotDataTable);

    useEffect(() => {
        if (chatbotApi.isDataTableOpen) {
            chatbotDataTableUtilities.registerTableControl();
        } else {
            chatbotDataTableUtilities.unregisterTableControl();
        }
    }, [chatbotApi.isDataTableOpen]);

    return (
        <>
            <Fab
                title={'Open Chatbot'}
                className={cx(classes.FloatingActionButton)}
                {...chainEventHandlers(bindToggle(popupState), {
                    onClick: (e: React.MouseEvent) => {
                        bindToggle(popupState).onClick(e);
                        if (!chatbotApi.currentTopic) {
                            chatbotApi.initStartTopic();
                        }
                    },
                })}
                color={'primary'}
                size={'small'}
                aria-owns={popupState.isOpen ? 'chatbot-popper' : undefined}
            >
                {popupState.isOpen ? (
                    <CloseRounded />
                ) : (
                    <Avatar alt={'cosmoo chatbot'} className={cx(classes.Test)}>
                        <CosmooV2 />
                    </Avatar>
                )}
            </Fab>
            <Popper
                {...bindPopper(popupState)}
                {...chainEventHandlers(bindPopper(popupState), {
                    onClose: (e: React.MouseEvent) => {
                        popupState.close();
                    },
                })}
                className={cx(classes.Popper)}
                placement='top-end'
                modifiers={[
                    {
                        name: 'flip',
                        enabled: false,
                        options: {
                            altBoundary: false,
                            rootBoundary: 'document',
                            padding: 8,
                        },
                    },
                    {
                        name: 'preventOverflow',
                        enabled: true,
                        options: {
                            altAxis: true,
                            altBoundary: true,
                            tether: true,
                            rootBoundary: 'document',
                            padding: 8,
                        },
                    },
                ]}
                id={'chatbot-popper'}
                transition
            >
                {({ TransitionProps }) => (
                    <CustomChatbotPopupContentControl TransitionProps={TransitionProps} close={popupState.close} chatbotApi={chatbotApi} />
                )}
            </Popper>
            {chatbotApi.chatbotDataObject && (
                <ChatbotDataDialog
                    data={chatbotApi.chatbotDataObject.data}
                    isOpen={chatbotApi.isDataTableOpen}
                    headerKeys={chatbotApi.chatbotDataObject.headers}
                    onCloseDialog={chatbotApi.onCloseDataTableDialog}
                    title={chatbotApi.chatbotDataObject.question}
                />
            )}
        </>
    );
};

const useStyles = customMakeStyles<ICustomChatbotContentProps>()((theme, props) => ({
    // We can also use zIndex 1199 if we wanted the button to be behind dialogs
    FloatingActionButton: { position: 'absolute', bottom: 14, right: 14, zIndex: '1301 !important' },
    Test: { width: '34px', height: '34px', backgroundColor: 'black' },
    Popper: { zIndex: '1301 !important' },
}));

export { CustomChatbotContent };
