import { useFlags } from 'launchdarkly-react-client-sdk';
import { ValueOptions } from '@mui/x-data-grid-premium';
import { CloudProvidersTypes } from '@vegaplatformui/models';

export function getProviderFilterOptions(): ValueOptions[] {
    const flags = useFlags();
    const providerOptions: ValueOptions[] = [
        { value: CloudProvidersTypes.Aws.toUpperCase(), label: CloudProvidersTypes.Aws.toUpperCase() },
        { value: CloudProvidersTypes.Azure.toUpperCase(), label: CloudProvidersTypes.Azure.toUpperCase() },
        { value: CloudProvidersTypes.Gcp.toUpperCase(), label: CloudProvidersTypes.Gcp.toUpperCase() },
    ];
    if (flags.ociIntegration) {
        providerOptions.push({ value: CloudProvidersTypes.Oci.toUpperCase(), label: CloudProvidersTypes.Oci.toUpperCase() });
    }
    return providerOptions;
}
