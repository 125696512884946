import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Button, Grid, Stack } from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { StyledToolTip } from '../utilities/styled-tooltip';
import { CreateScheduleMenu } from './create-schedule-menu';
import { DrawerScheduler } from '../drawer-scheduler/drawer-scheduler';
import { ConfirmDeleteScheduleDialog } from './confirm-delete-schedule-dialog';
import { RemoveAdvancedParkingDialog } from './remove-advanced-parking-dialog';
import { IParkingScheduleSummary, ParkingScheduleType } from '@vegaplatformui/models';
import { IBulkParkingSchedulesActionsProps as Props } from './parking-types';
import { ParkingScheduleActionMenu } from './parking-schedule-action-menu';
import { ChangeScheduleStatusDialog } from './change-schedule-status-dialog/change-schedule-status-dialog';
import { DeleteExemptionDialog } from './parking-schedule-exemptions/delete-exemption-dialog';
import { useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { ParkingScheduleBusinessGroupId } from '@vegaplatformui/sharedcomponents';
import { useRouteUrls } from '@vegaplatformui/utils';

// eslint-disable-next-line @typescript-eslint/no-empty-interface

export interface IBulkParkingSchedulesActionsProps extends Props {
    scheduleToDelete: IParkingScheduleSummary | undefined;
    setScheduleToDelete: React.Dispatch<React.SetStateAction<IParkingScheduleSummary | undefined>>;
    isCreationEnabled: boolean;
}
const BulkParkingSchedulesActions: React.FC<IBulkParkingSchedulesActionsProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const [parkingScheduleType, setParkingScheduleType] = React.useState<ParkingScheduleType>(ParkingScheduleType.Basic);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [actionsAnchorEl, setActionsAnchorEl] = React.useState<null | HTMLElement>(null);
    const setBusinessGroupingId = useSetRecoilState(ParkingScheduleBusinessGroupId);
    const location = useLocation();
    const { routeUrls } = useRouteUrls({});
    const isScheduleActionsMenuOpen = Boolean(anchorEl);

    React.useEffect(() => {
        setParkingScheduleType(props.parkingScheduleType ? props.parkingScheduleType : props.scheduleToEdit?.type_str ?? ParkingScheduleType.Basic);
    }, [props.parkingScheduleType, props.scheduleToEdit]);

    const onCloseDrawer = () => {
        props.setScheduleToEdit(undefined);
        props.onToggleDrawer(false);
        setAnchorEl(null);
    };

    const onClickDelete = (schedule: IParkingScheduleSummary) => {
        props.setIsConfirmDeleteDialogOpen(false);
        props.onClickDeleteSchedule(schedule);
    };

    const onClickCreate = (parkingScheduleType: ParkingScheduleType) => {
        setParkingScheduleType(parkingScheduleType);
        if (!location.pathname.includes(routeUrls.businessGroupings.path)) {
            setBusinessGroupingId('');
        }
        props.onToggleDrawer(true);
    };

    const onClickDeleteExemption = (schedule: IParkingScheduleSummary) => {
        props.setIsDeleteExemptionDialogOpen(false);
        props.onClickDeleteExemption(schedule);
    };

    return (
        <>
            <DrawerScheduler
                setResourcesSelectedForParking={props.setResourcesSelectedForParking}
                isServerPaginated={true}
                scheduleToEdit={props.scheduleToEdit}
                resources={props.parkableResources}
                isLoading={props.isParkableResourcesLoading}
                width={'60%'}
                isOpen={props.isDrawerOpen}
                onCloseDrawer={onCloseDrawer}
                onSave={props.scheduleToEdit ? props.onClickEditSchedule : props.onClickCreateSchedule}
                onDelete={props.onClickDeleteSchedule}
                parkingScheduleType={parkingScheduleType}
                usersBusinessGroupings={props.usersBusinessGroupings}
                loadParkableResources={props.loadParkableResources}
                selectedResources={props.selectedResources}
            />
            <DeleteExemptionDialog
                isOpen={props.isDeleteExemptionDialogOpen}
                onClickDeleteExemption={onClickDeleteExemption}
                onClose={() => {
                    props.setIsDeleteExemptionDialogOpen(false);
                    setTimeout(() => {
                        props.setScheduleWithExemption(undefined);
                    }, 200);
                }}
                schedule={props.scheduleWithExemption}
            />
            <ConfirmDeleteScheduleDialog
                isConfirmDeleteDialogOpen={props.isConfirmDeleteDialogOpen}
                onCloseConfirmDeleteDialog={() => {
                    props.setIsConfirmDeleteDialogOpen(false);
                    setTimeout(() => {
                        props.setScheduleToDelete(undefined);
                        props.setSelectedSchedules([]);
                    }, 200);
                }}
                confirmDeleteSchedule={onClickDelete}
                scheduleToDelete={props.scheduleToDelete}
                schedulesToDelete={props.selectedSchedules}
                onClickDeleteSelectedSchedules={() => {
                    props.setIsConfirmDeleteDialogOpen(false);
                    props.onClickDeleteSelectedSchedules();
                }}
            />
            <ChangeScheduleStatusDialog
                show={props.isChangeScheduleDialogOpen}
                onClose={() => {
                    props.setIsChangeScheduleDialogOpen(false);
                    props.setScheduleToEdit(undefined);
                }}
                onChangeScheduleStatuses={props.onChangeScheduleStatuses}
                isScheduled={props.isScheduled}
                schedulesToChangeStatusOn={props.schedulesToChangeStatusOn}
            />
            <RemoveAdvancedParkingDialog
                show={props.isRemoveAdvancedParkingDialogOpen}
                onClose={() => {
                    props.setIsRemoveAdvancedParkingDialogOpen(false);
                    props.setScheduleToEdit(undefined);
                }}
                onRemoveAdvancedParking={props.onRemoveAdvancedParking}
                scheduleToEdit={props.scheduleToEdit}
            />
            <Grid xs={8} item container justifyContent={'flex-end'}>
                <Stack direction={'row'} spacing={1} sx={{ height: '2rem' }}>
                    <Button
                        color={'inherit'}
                        variant={'cancel'}
                        aria-expanded={!!actionsAnchorEl}
                        onClick={(e) => setActionsAnchorEl(e.currentTarget)}
                        disabled={props.selectedSchedules.length <= 1}
                        endIcon={isScheduleActionsMenuOpen ? <ArrowDropUp /> : <ArrowDropDown />}
                    >
                        Actions
                    </Button>
                    <ParkingScheduleActionMenu
                        anchorEl={actionsAnchorEl}
                        setAnchorEl={setActionsAnchorEl}
                        selectedSchedules={props.selectedSchedules}
                        onClickOpenDeleteDialog={props.onClickOpenDeleteDialog}
                        onOpenEnableDisableSchedulesDialog={props.onOpenEnableDisableSchedulesDialog}
                        onOpenRemoveAdvancedParkingDialog={props.onOpenRemoveAdvancedParkingDialog}
                        onClickOpenDeleteExemptionDialog={props.onClickOpenDeleteExemptionDialog}
                    />
                    {props.isCreationEnabled && (
                        <StyledToolTip title={!props.userHasGroupings ? 'User has no assigned business groupings.' : ''}>
                            {/* needs this wrapper so the disabled mui button can be not hidden and show tooltip */}
                            <span>
                                <CreateScheduleMenu
                                    anchorEl={anchorEl}
                                    setAnchorEl={setAnchorEl}
                                    onClickCreate={onClickCreate}
                                    parkableResources={props.parkableResources}
                                    userHasGroupings={props.userHasGroupings}
                                />
                            </span>
                        </StyledToolTip>
                    )}
                </Stack>
            </Grid>
        </>
    );
};

const useStyles = customMakeStyles<IBulkParkingSchedulesActionsProps>()((theme, props) => ({}));

export { BulkParkingSchedulesActions };
