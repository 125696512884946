import React from 'react';
import { customMakeStyles } from '../make-styles/make-styles';
import { green, grey, pink } from '@mui/material/colors';
import { alpha } from '@mui/material/styles';

export const useCommonStyles = customMakeStyles()((theme) => ({
    TooltipIcons: {
        fill: theme.palette.grey['500'],
        fontSize: '1rem',
    },
    DarkIconColor: { color: theme.palette.grey[700] },
    LowercaseTextButton: {
        textTransform: 'capitalize',
    },
    AggregatedHeader: {
        '& .MuiDataGrid-aggregationColumnHeader': {
            visibility: 'hidden',
        },
        '& .MuiDataGrid-columnHeaderTitle': {
            visibility: 'visible',
            alignSelf: 'center',
        },
    },
    DataGridActionsHeader: { fontSize: 0 },
    DataGridToolBarFilter: {
        color: theme.palette.grey[500],
        marginBottom: '1rem',
    },
    DataGridToolBar: {
        color: theme.palette.grey[100],
        '& .MuiFormControl-root': {
            minWidth: '100%',
        },
    },
    CloudProviderTableIcon: {
        width: '1.5rem',
    },
    ChronDialog: {
        zIndex: 2,
    },
    CancelButton: { color: theme.palette.grey[50] },
    DialogActions: {
        marginTop: '1rem',
        marginRight: '-.5rem',
    },
    FormTitle: {
        cursor: 'move',
        fontWeight: 600,
        marginTop: '1rem',
        marginBottom: '.5rem',
    },
    PageCardSubtitle: {
        color: theme.palette.grey[600],
    },
    SubTitle: {
        paddingBottom: '1rem',
    },
    FormatJson: {
        whiteSpace: 'pre',
    },
    CaptionText: {
        color: theme.palette.grey[400],
        fontWeight: 700,
    },
    GreyButton: {
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.grey[900],
        '&:hover': {
            backgroundColor: theme.palette.grey[200],
        },
    },
    MenuItemWithCategorySpacing: {
        paddingLeft: theme.spacing(4),
    },
    MultipleDeleteButton: {
        color: theme.palette.getContrastText(grey[500]),
        backgroundColor: grey[300],
        '&:hover': {
            backgroundColor: grey[500],
        },
    },
    RedGreenSwitch: {
        '& .MuiSwitch-switchBase': {
            color: pink[600],
        },
        '& .MuiSwitch-switchBase + .MuiSwitch-track': {
            backgroundColor: pink[600],
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: green[600],
            '&:hover': {
                backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
            },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: green[600],
        },
    },
    ItemLoading: {
        animation: 'blink 1.5s infinite',
        '@keyframes blink': {
            '0%': { backgroundColor: 'rgba(0, 0, 0, 0.05)' },
            '25%': { backgroundColor: 'rgba(0, 0, 0, 0.1)' },
            '50%': { backgroundColor: 'rgba(0, 0, 0, 0.2)' },
            '75%': { backgroundColor: 'rgba(0, 0, 0, 0.1)' },
            '100%': { backgroundColor: 'rgba(0, 0, 0, 0.05)' },
        },
    },
}));
