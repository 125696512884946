import { atom } from 'recoil';
import React from 'react';
import { CustomSnackBarOptions } from '../custom-snackbar/custom-snackbar';
import { IDiscoveryDetails, IOrganizationLogoState, SupportEmailRecipient, IOrganizationThemeColorState } from '@vegaplatformui/models';
import { GridColumnVisibilityModel, GridDensity, GridPaginationModel, GridSortModel } from '@mui/x-data-grid-premium';
import { GridFilterModel } from '@mui/x-data-grid';
import { GeminiMenuItem } from '@vegaplatformui/sharedassets';
import { localStorageTexts, sessionTexts } from '@vegaplatformui/utils';

export const CustomColorsState = atom<IOrganizationThemeColorState>({
    key: 'CustomColorsState',
    default: {
        isCustomColors: false,
        primary_color: '',
        secondary_color: '',
    },
});

export const CustomPrimaryLogoState = atom<IOrganizationLogoState>({
    key: 'CustomPrimaryLogoState',
    default: {
        isCustom: false,
        url: '',
    },
});

export const CustomSecondaryLogoState = atom<IOrganizationLogoState>({
    key: 'CustomSecondaryLogoState',
    default: {
        isCustom: false,
        url: '',
    },
});

export const CustomFaviconState = atom<IOrganizationLogoState>({
    key: 'CustomFaviconState',
    default: {
        isCustom: false,
        url: '',
    },
});

export const CommonPageHeader = atom<React.ReactNode>({
    key: 'CommonPageHeader',
    default: undefined,
});

export const OrganizationId = atom<string>({ key: 'OrganizationId', default: '' });

export const OrganizationLoading = atom<boolean>({ key: 'OrganizationLoading', default: false });
export const UserId = atom<string | undefined>({ key: 'UserId', default: undefined });

export const ViewPortHeightAndWidth = atom<{ height: number; width: number }>({
    key: 'ReportsHeightAndWidth',
    default: {
        height: 0,
        width: 0,
    },
});

export const QuicksightReportLoading = atom<boolean>({
    key: 'QuicksightReportLoading',
    default: false,
});

export const IsEditingExemption = atom<boolean>({
    key: 'IsEditingExemption',
    default: false,
});

export const QuicksightConsoleLoading = atom<boolean>({
    key: 'QuicksightConsoleLoading',
    default: false,
});

export const OrgKeycloakRealm = atom<string>({
    key: 'OrgKeycloakRealm',
    default: undefined,
});

export const ShowSupportForm = atom<{ showSupportForm: boolean; contactType: SupportEmailRecipient }>({
    key: 'ShowSupportForm',
    default: { showSupportForm: false, contactType: SupportEmailRecipient.Support },
});

export const ApplicationError = atom<Error | undefined>({
    key: 'ApplicationError',
    default: undefined,
});

export const SnackBarOptions = atom<CustomSnackBarOptions>({
    key: 'snackBarOptions',
    default: {
        snackBarProps: {},
        alertProps: {},
        message: '',
    },
});

export const ApiKeyState = atom<string | null>({
    key: 'ApiKeyState',
    default: null,
});

export const ProfilePhotoUrl = atom<string>({
    key: 'ProfilePhotoUrl',
    default: '',
});

export const DiscoveryDetails = atom<IDiscoveryDetails>({
    key: 'DiscoveryDetails',
    default: {
        in_cooldown: false,
        is_discovery: false,
        request_id: '',
        client_id: '',
        datetime_in_30min: 0,
        shouldConnect: false,
    },
});

export const areParkingScheduleTabsDirty = atom<boolean>({ key: 'areParkingScheduleTabsDirty', default: false });

export const vegaTableControls = atom<IVegaTableControls[]>({
    dangerouslyAllowMutability: true,
    key: 'VegaTableControls',
    default: JSON.parse(localStorage.getItem(`${localStorageTexts.tableControls}_${sessionStorage.getItem(sessionTexts.keycloakUserId)}`) ?? '[]'),
});

export interface IVegaTableControls {
    key: string;
    value: IVegaTableControl;
}

export interface IVegaTableControl {
    totalRows?: number;
    paginationModel: GridPaginationModel;
    filterModel: GridFilterModel;
    sortModel: GridSortModel;
    density: GridDensity | undefined;
    columnModel: GridColumnVisibilityModel | undefined;
}

export const defaultVegaTableControl: IVegaTableControl = {
    paginationModel: { page: 0, pageSize: 15 },
    filterModel: { items: [], quickFilterValues: [] },
    columnModel: {},
    density: undefined,
    sortModel: [],
    totalRows: 0,
};

export const ParkingScheduleBusinessGroupId = atom<string>({
    key: 'ParkingScheduleBusinessGroupId',
    default: '',
});

export const ParkingScheduleTableBusinessGroupId = atom<string>({
    key: 'ParkingScheduleTableBusinessGroupId',
    default: '',
});

export const ParkingScheduleResourceId = atom<string>({
    key: 'ParkingScheduleResourceId',
    default: '',
});

export const ResourceIdToRefresh = atom<string | undefined>({
    key: 'ResourceIdToRefresh',
    default: undefined,
});

export const NotificationInboxAnchorElAtom = atom<HTMLElement | null>({
    key: 'NotificationInboxAnchorElAtom',
    default: null,
});

export const MenuItems = atom<GeminiMenuItem[]>({
    dangerouslyAllowMutability: true,
    key: 'MenuItems',
    default: [],
});

export const menuItemState = atom<GeminiMenuItem>({
    key: 'menuItemState',
    default: undefined,
});

export const ExpandedBusinessGroupTreeNodes = atom<string[]>({
    key: 'ExpandedBusinessGroupTreeNodes',
    default: [],
});
