import React, { useEffect } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { IResource } from '@vegaplatformui/models';
import { ResourcesTable } from '../../resources/resource-table/resources-table';
import { tableIdentifierKeys, useTableUtilities } from '@vegaplatformui/sharedcomponents';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAttachResourcesDialogProps {
    isLoading: boolean;
    attachableResources: IResource[];
    isDialogOpen: boolean;
    onCloseDialog: () => void;
    onAttachResources: (resources: IResource[]) => void;
}

const AttachResourcesDialog: React.FC<IAttachResourcesDialogProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const [selectedResources, setSelectedResources] = React.useState<IResource[]>([]);
    const AttachResourcesDialogTableIdentifier = tableIdentifierKeys.attachResourcesDialogTable;
    const activeFields = [
        'name',
        'alert',
        'resource_id',
        'provider_str',
        'provider_account_canonical_name',
        'cloud_account_id',
        'type_str',
        'is_attached_to_schedule',
        'region',
        'state',
    ];

    useEffect(() => {
        if (!props.isDialogOpen) {
            setSelectedResources([]);
        }
    }, [props.isDialogOpen]);

    return (
        <Dialog
            maxWidth={'md'}
            fullWidth
            open={props.isDialogOpen}
            onClose={props.onCloseDialog}
            aria-labelledby='parking-schedule-attach-resources-dialog'
        >
            <DialogTitle variant={'h6'}>
                Attach new resources
                <Typography>Select resources to attach to this schedule</Typography>
            </DialogTitle>
            <DialogContent>
                <ResourcesTable
                    resources={props.attachableResources}
                    setSelectedResources={setSelectedResources}
                    selectedResources={selectedResources}
                    isLoading={props.isLoading}
                    isServerPaginated={true}
                    resourcesTableIdentifier={AttachResourcesDialogTableIdentifier}
                    checkBoxSelection={true}
                    activeFields={activeFields}
                    shouldLimitRowSelection={true}
                />
            </DialogContent>
            <DialogActions>
                <Button variant={'cancel'} onClick={props.onCloseDialog}>
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        props.onCloseDialog();
                        props.onAttachResources(selectedResources);
                    }}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = customMakeStyles<IAttachResourcesDialogProps>()((theme, props) => ({}));

export { AttachResourcesDialog };
