import React, { useEffect } from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { Card, CardContent, Stack, Typography, Box, Grid, Chip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Add, Circle, Delete } from '@mui/icons-material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { DataGridPremium, GridColDef, GridDensity, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-premium';
import { CustomGridColSingleSelectOperatorDef, CustomGridColStringOperatorDef } from '../utilities/custom-grid-col-string-operator-def';
import { StyledToolTip } from '../utilities/styled-tooltip';
import { ClientRegistrationDialog } from './client-registration-dialog';
import { ClientDeletionDialog } from './client-deletion-dialog';
import { ClientStatus, IClient, INewClient, INewClientRegistrationInformation } from '@vegaplatformui/models';
import { ClientRegistrationSuccessDialog } from './client-registration-success-dialog';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IClientRegistrationCardProps {
    clients: IClient[];
    expirationOptions: {};
    areRegisteredClientsLoading: boolean;
    isClientRegistrationLoading: boolean;
    isDeleteLoading: boolean;
    createNewClientRegistration: (payload: INewClient) => void;
    deleteClientRegistration: (clientId: string) => void;
    isClientRegistrationSuccess: boolean;
    isClientDeletionSuccess: boolean;
    newClientInformation: INewClientRegistrationInformation | undefined;
    unsetClientInformation: () => void;
}

const ClientRegistrationCard: React.FC<IClientRegistrationCardProps> = (props) => {
    const { cx, classes } = useStyles(props);
    const commonStyles = useCommonStyles();
    const [density, setDensity] = React.useState<GridDensity>('standard');
    const [isCreateClientDialogOpen, setIsCreateClientDialogOpen] = React.useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
    const [clientIdToDelete, setClientIdToDelete] = React.useState('');

    const closeClientRegistrationDialog = () => {
        setIsCreateClientDialogOpen(false);
    };

    const closeClientDeletionDialog = () => {
        setIsDeleteDialogOpen(false);
        setClientIdToDelete('');
    };

    const onClickDeleteIcon = (clientId: string) => {
        setIsDeleteDialogOpen(true);
        setClientIdToDelete(clientId);
    };

    const onDeleteConfirmation = () => {
        props.deleteClientRegistration(clientIdToDelete);
        closeClientDeletionDialog();
    };

    const onClickCreateNewClient = (payload: INewClient) => {
        props.createNewClientRegistration(payload);
        closeClientRegistrationDialog();
    };

    useEffect(() => {
        if (props.isClientDeletionSuccess) closeClientDeletionDialog();
    }, [props.isClientDeletionSuccess]);

    const columns: GridColDef[] = [
        {
            ...CustomGridColStringOperatorDef,
            filterable: false,
            disableColumnMenu: true,
            field: 'client_name',
            headerName: 'Name',
            flex: 1,
        },
        {
            ...CustomGridColStringOperatorDef,
            filterable: false,
            disableColumnMenu: true,
            field: 'client_id',
            headerName: 'Client ID',
            flex: 1,
        },
        {
            ...CustomGridColStringOperatorDef,
            filterable: false,
            disableColumnMenu: true,
            field: 'client_secret_hidden',
            headerName: 'Secret Key',
            flex: 1,
        },
        {
            ...CustomGridColStringOperatorDef,
            filterable: false,
            disableColumnMenu: true,
            field: 'expiration_date',
            headerName: 'Expiration Date',
            flex: 1,
            valueFormatter: (value: string, row: IClient) =>
                Intl.DateTimeFormat('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric',
                }).format(Date.parse(value)),
        },
        {
            ...CustomGridColSingleSelectOperatorDef,
            filterable: false,
            disableColumnMenu: true,
            sortable: true,
            field: 'deleted_from_idp_client',
            headerName: 'Status',
            valueGetter: (value: boolean, row: IClient) => {
                //If deleted is true
                if (value) {
                    return ClientStatus.Expired;
                } else {
                    return ClientStatus.Active;
                }
            },
            renderCell: (params: GridRenderCellParams<IClient>) => {
                return (
                    <Chip
                        className={params.value === ClientStatus.Active ? cx(classes.StatusChipActive) : cx(classes.StatusChipInactive)}
                        size={'small'}
                        icon={
                            <Circle
                                className={params.value === ClientStatus.Active ? cx(classes.ChipCircleActive) : cx(classes.ChipCircleInactive)}
                                color={params.value === ClientStatus.Active ? 'success' : 'error'}
                            />
                        }
                        label={params.value}
                    />
                );
            },
            //Adding this here just in case we do filtering, Since the UI shows 'Expired' but the actual value is 'Inactive'
            type: 'singleSelect',
            valueOptions: [
                { value: ClientStatus.Active, label: ClientStatus.Active },
                { value: ClientStatus.Expired, label: ClientStatus.Expired },
            ],
            flex: 0.6,
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            flex: 0.5,
            getActions: (params) => [
                <StyledToolTip arrow title={'Delete'}>
                    <GridActionsCellItem
                        icon={<Delete />}
                        aria-label='delete client registration'
                        label={'Delete'}
                        onClick={() => {
                            onClickDeleteIcon(params.row.client_id);
                        }}
                    />
                </StyledToolTip>,
            ],
        },
    ];

    return (
        <Card elevation={0}>
            <CardContent className={cx(classes.CardContent)}>
                <Grid container className={cx(classes.BottomGap)}>
                    <Grid item xs={6} display='flex' justifyContent={'flex-start'} alignItems={'center'}>
                        <Stack direction='column' display='flex' justifyContent='flex-start' sx={{ mb: '16px' }}>
                            <Typography variant={'h3'}>API Client Registration</Typography>
                            <Typography className={commonStyles.classes.PageCardSubtitle} variant='body2'>
                                Register your API client for seamless integration; limit 5 registrations.
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={6} display='flex' justifyContent={'flex-end'} alignItems={'center'}>
                        <Stack display={'flex'} justifyContent={'flex-end'}>
                            <LoadingButton
                                variant={'contained'}
                                startIcon={<Add />}
                                size={'small'}
                                loading={props.isClientRegistrationLoading}
                                disabled={props.clients?.length >= 5 ?? false}
                                onClick={() => {
                                    setIsCreateClientDialogOpen(true);
                                }}
                            >
                                API Client Registration
                            </LoadingButton>
                        </Stack>
                    </Grid>
                </Grid>
                <DataGridPremium
                    className={cx(classes.DataGridCustomStyle)}
                    density={density}
                    onDensityChange={(newDensity) => setDensity(newDensity)}
                    loading={props.areRegisteredClientsLoading || props.isClientRegistrationLoading || props.isDeleteLoading}
                    autoHeight={true}
                    disableRowSelectionOnClick={true}
                    columns={columns}
                    rows={props.clients}
                    slots={{
                        toolbar: undefined,
                    }}
                    slotProps={{
                        noRowsOverlay: {
                            action: 'adding an API Client Registration',
                        },
                    }}
                    hideFooter
                />
                <ClientRegistrationDialog
                    isDialogOpen={isCreateClientDialogOpen}
                    onClose={closeClientRegistrationDialog}
                    clientName={undefined}
                    expiration={undefined}
                    expirationOptions={props.expirationOptions}
                    onDataSubmit={onClickCreateNewClient}
                />
                <ClientDeletionDialog isDialogOpen={isDeleteDialogOpen} onDelete={onDeleteConfirmation} onClose={closeClientDeletionDialog} />
                <ClientRegistrationSuccessDialog
                    isDialogOpen={!!props.newClientInformation}
                    onClose={() => props.unsetClientInformation()}
                    newClientInformation={props.newClientInformation ?? { client_id: '', client_secret: '' }}
                />
            </CardContent>
        </Card>
    );
};

const useStyles = customMakeStyles<IClientRegistrationCardProps>()((theme, props) => ({
    StatusChipActive: { backgroundColor: `#ECFDF3`, mixBlendMode: 'multiply', color: '#027A48' },
    StatusChipInactive: { backgroundColor: 'rgba(228, 119, 146, 0.25)', mixBlendMode: 'multiply', color: theme.palette.error.dark },
    ChipCircleActive: {
        color: '#12B76A',
        height: '8px',
        width: '8px',
    },
    ChipCircleInactive: {
        color: theme.palette.error.dark,
        height: '8px',
        width: '8px',
    },
    DataGridCustomStyle: {
        minWidth: '790px',
        '& .MuiDataGrid-main': {
            borderTop: `1px solid ${theme.palette.common.tableBorder}`,
            borderLeft: `1px solid ${theme.palette.common.tableBorder}`,
            borderRight: `1px solid ${theme.palette.common.tableBorder}`,
            borderBottom: `1px solid ${theme.palette.common.tableBorder}`,
            borderRadius: '8px 8px 8px 8px',
        },
    },
    BottomGap: {
        marginBottom: '1rem',
    },
    CardContent: {
        maxWidth: '1224px',
    },
}));

export { ClientRegistrationCard };
