import React, { ReactNode } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Grid, Typography } from '@mui/material';
import { NotificationSettingTabLoadingSkeleton } from './notification-setting-tab-loading-skeleton';

export interface INotificationSettingsTabProps {
    title: string;
    subtitle: string;
    isLoading: boolean;
    children: ReactNode | ReactNode[];
}

const NotificationSettingsTab: React.FC<INotificationSettingsTabProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return (
        <Grid container justifyContent='flex-start' alignItems='center' direction={'row'}>
            <Grid item xs={12} className={cx(classes.NotificationHeader)}>
                <Typography variant={'h3'}>{props.title}</Typography>
                <Typography className={cx(classes.NotificationCaptionText)} role={'presentation'} variant={'body2'}>
                    {props.subtitle}
                </Typography>
            </Grid>
            {!props.isLoading ? props.children : <NotificationSettingTabLoadingSkeleton />}
        </Grid>
    );
};

const useStyles = customMakeStyles<INotificationSettingsTabProps>()((theme, props) => ({
    NotificationHeader: { marginBottom: '2rem' },
    NotificationCaptionText: { color: theme.palette.text.secondary },
}));

export { NotificationSettingsTab };
